<template>
  <div>
    <section id="home-1" class="home-section home-section-menu-fixo section-1">
      <header class="fixo">
        <div class="header-inner-wrapper">
          <div class="elemento-right">
            <img src="/assets/imagens/logotipo.svg" alt="logotipo" />
          </div>
        </div>
      </header>
      <main>
        <div class="main-inner-wrapper">
          <div class="main-coluna-conteudo">
            <div class="main-coluna-conteudo-inner-wrapper self-center">
              <h2 class="extra-h1">
                A harmonia<br />entre as <span class="green">emoções</span
                ><br />
                e a tecnologia.
              </h2>
              <div class="font-size-26 font-weight-500">
                Emotional Technology dá<br />
                expressão a uma ideia
                <b
                  >poderosa,<br />
                  quase magnética.</b
                >
              </div>
              <div class="separador-linha"></div>
            </div>
          </div>
          <div class="main-coluna-imagens">
            <div class="imagens-wrapper swiper-wrapper">
              <div
                class="swiper-slide"
                style="
                  background-image: url('./assets/imagens/home_scroll_2.jpg');
                "
              ></div>
            </div>
          </div>
        </div>
      </main>
      <a v-scroll-to="'#home-2'" class="footer-avancar big relative"></a>
    </section>
    <section id="home-2" class="home-section home-section-menu-fixo section-2">
      <header style="height: 0px">
        <a
          v-scroll-to="'#home-1'"
          class="header-voltar big header-relative"
        ></a>
      </header>
      <main>
        <div class="main-inner-wrapper">
          <div class="main-coluna-conteudo">
            <div class="main-coluna-conteudo-inner-wrapper self-center">
              <h2 class="extra-h1">
                Entrámos numa<br />nova era da vida<br />da Farmácia.
              </h2>
              <div class="font-size-26 font-weight-500">
                Uma Farmácia inteligente,<br />digital e evoluída ao
                <b
                  >serviço<br />
                  das emoções.</b
                >
              </div>
              <div class="separador-linha"></div>
            </div>
          </div>
          <div class="main-coluna-imagens">
            <div class="imagens-wrapper swiper-wrapper">
              <div
                class="swiper-slide"
                style="
                  background-image: url('./assets/imagens/home_scroll_3.jpg');
                "
              ></div>
            </div>
          </div>
        </div>
      </main>
      <a v-scroll-to="'#home-3'" class="footer-avancar big relative"></a>
    </section>
    <section id="home-3" class="home-section home-section-menu-fixo section-3">
      <header style="height: 0px">
        <a
          v-scroll-to="'#home-2'"
          class="header-voltar big header-relative"
        ></a>
      </header>
      <main>
        <div class="main-inner-wrapper">
          <div class="main-coluna-conteudo">
            <div class="main-coluna-conteudo-inner-wrapper self-center">
              <h2 class="extra-h1">
                Este é o caminho <br />
                que vamos percorrer,<br />juntos.
              </h2>
              <div class="font-size-26 font-weight-500">
                Mas não sozinhos.<br />
                Agora, temos um
                <span class="orange" style="font-weight: bold">guia.</span>
              </div>
              <div class="separador-linha"></div>
            </div>
          </div>
          <div class="main-coluna-imagens">
            <div class="imagens-wrapper swiper-wrapper">
              <div
                class="swiper-slide"
                style="
                  background-image: url('./assets/imagens/home_scroll_4.jpg');
                "
              ></div>
            </div>
          </div>
        </div>
      </main>
      <a v-scroll-to="'#home-4'" class="footer-avancar big relative"></a>
    </section>
    <section id="home-4" class="home-section seccao-imagem-full-screen">
      <div
        class="seccao-bg"
        style="
          background-image: url('./assets/imagens/bg-seccao-full-screen.jpg');
        "
      >
        <header>
          <div class="header-inner-wrapper">
            <div class="elemento-right">
              <img src="@/assets/imagens/logotipo.svg" alt="logotipo" />
            </div>
          </div>
          <a
            v-scroll-to="'#home-3'"
            class="header-voltar white big"
            style="top: 20px"
          ></a>
        </header>
        <main>
          <div class="main-inner-wrapper">
            <h2 class="white elemento-center line-height-text">
              <span
                class="text-uppercase texto-font-2 green-strap font-weight-500 white letter-spacing-20"
                >Bem-vindo ao<br />Emotional Technology</span
              >
              <span
                class="extra-h1 title-with-strap green-strap white extra-strap"
                >Guide</span
              >
            </h2>
          </div>
        </main>
        <footer>
          <div
            class="footer-inner-wrapper text-uppercase elemento-center white h3 letter-spacing-5"
          >
            O portal da farmácia da nova era
          </div>
          <router-link to="/home">
            <a
              v-scroll-to="'#seccao-servicos'"
              class="button ghost diagonal white"
              id="botao-entrar"
              >Começar</a
            >
          </router-link>
        </footer>
      </div>
    </section>
  </div>
</template>

<script type="text/javascript" src="@/assets/ficheiros/home.js"></script>

<script>
  export default {
    name: "home",
    // mounted: function () {

    //     let isLogged = window.sessionStorage.getItem('user_id')
    //     const result = isLogged == null ? false : true

    //     if(!result) {
    //         // console.error(window.sessionStorage.getItem('user_id'))
    //         window.location.href = "/login"
    //     }
    //     else {
    //         console.log("session is valid")
    //     }
    // }
  };
</script>

<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>

<style scoped>
  .seccao-imagem-full-screen footer {
    display: flex;
    align-items: center;
    flex-flow: column;
  }
  #botao-entrar {
    margin-top: 15px;
    color: #000;
    padding: 0.8rem 4rem;
    font-size: 2.7vh;
  }
  #botao-entrar:hover {
    cursor: pointer;
    font-weight: bold;
  }
  #botao-entrar::before {
    background-color: #fff !important;
  }
  .always-white {
    background-color: #fff !important;
  }
  .links {
    text-decoration: none;
    color: inherit;
    width: 100%;
    position: inherit;
    margin: inherit;
  }
  #saber-mais:hover {
    cursor: pointer;
    font-weight: bold;
  }
  .orange-link:hover {
    cursor: pointer;
  }
  .header-relative {
    position: relative;
    top: 20px;
  }
  .big.relative {
    bottom: 50px;
  }
  .relative {
    position: relative;
  }
  .section-2 main .main-inner-wrapper .main-coluna-conteudo h2 {
    -webkit-text-fill-color: transparent;
    background: url("/assets/imagens/home_scroll_texto_3.jpg") repeat;
    background: -o-linear-gradient(transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .section-3 main .main-inner-wrapper .main-coluna-conteudo h2 {
    -webkit-text-fill-color: transparent;
    background: url("/assets/imagens/home_scroll_texto_4.jpg") repeat;
    background: -o-linear-gradient(transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .self-center {
    align-self: center;
  }

  .seccao-imagem-full-screen main {
    height: calc(100vh - 35vh - 70px);
  }
  .seccao-imagem-full-screen footer {
    position: relative;
    height: 35vh;
    width: 100%;
  }

  .seccao-imagem-full-screen footer .footer-inner-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 20px;
    height: calc(100% - 120px);
  }
</style>
